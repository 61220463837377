import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BG from "../assets/2015/11/MAZD0294-Mazda-Foundation-Website-V4_03.jpg"
import Icon1 from "../assets/2015/11/icon1.png"
import Icon2 from "../assets/2015/11/icon2.png"
import Icon3 from "../assets/2015/11/icon3.png"
import CHE from "../assets/2024/10/CHEP-Logo.jpg"
import OMD from "../assets/2020/10/OMD.svg"
import BDA from "../assets/2020/11/bda.png"
import Castrol from "../assets/2024/10/castrol.png"
import PeterJones from "../assets/2024/10/PJSE-Logo.jpg"
import MDAOA from "../assets/2020/11/MDAOA.png"

const IndexPage = () => (
  <Layout page="home">
    <SEO title="Australian Community Charity Projects" />
    <div
      id="home"
      style={{
        background: `url(${BG}) repeat`
      }}>
      <div id="title" className="green-bg vertical-padding center">
        <div className="container">
          <div className="horizontal-padding">
            <h1 className="large">WELCOME TO THE<br/>MAZDA FOUNDATION</h1>
            <p className="large">We are dedicated to enriching Australian communities. Fostering a spirit of community involvement, the foundation supports a broad range of charitable projects with assistance and funding.</p>
          </div>
        </div>
      </div>
      <div className="vertical-padding-small center">
        <div className="container">
          <div id="foundation_Priorities">
            <h2>Foundation Priorities</h2>
            <div className="hr hr1"></div>
            <div>
              <h3>Primary Producers facing hardship</h3>
              <p>Community based organisations with projects to strengthen and build resilience in primary producer communities.</p>
            </div>
            <div>
              <h3>Literacy and Numeracy</h3>
              <p>Programs addressing literacy and numeracy issues amongst primary school aged children, especially remedial programs for children from disadvantaged areas.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="vertical-padding-small center">
        <div className="container">
          <div id="entry">
            <div className="columns">
              <div className="one_third column">
                <div className="icon_1">
                  <img
                    src={Icon1}
                    alt="Foundation"
                    width="91"
                    height="92" />
                </div>
                <h2 className="small">Foundation</h2>
                <div className="hr"></div>
                <p>The work of the Mazda Foundation began in 1990 when it was formed to provide assistance to a broad cross section of individuals and important causes throughout Australia.</p>
                <Link className="button" to="/about">About</Link>
              </div>
              <div className="one_third column">
                <div className="icon_1">
                  <img
                    src={Icon3}
                    alt="Goals"
                    width="97"
                    height="71" />
                </div>
                <h2 className="small">Goals</h2>
                <div className="hr"></div>
                <p>The Mazda Foundation aims to provide financial aid for community advancement, such as the improvement
                  of education and employment skills, development of technology and the protection of the environment.
                </p>
                <Link className="button" to="/foundation-objectives">Objectives</Link>
              </div>
              <div className="one_third column">
                <div className="icon_1">
                  <img
                    src={Icon2}
                    alt="Funding"
                    width="104"
                    height="59" />
                </div>
                <h2 className="small">Funding</h2>
                <div className="hr"></div>
                <p>Since inception the Foundation has provided in excess of $19.5 million to various projects and activities throughout Australia.</p>
                <Link className="button" to="/report-to-the-community">View reports</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="green-bg vertical-padding-small horizontal-padding center" >
          <div className="container">
            <div id="winner">
              <h2>Mazda Foundation Gala Dinner Raffle</h2>
              <div className="hr hr1"></div>
              <div>
                <h3>Congratulations to Greg Strydom</h3>
                <p>Ticket # 469 the winner of a Mazda CX-80 A 8A G40E Pure AWD in Jet Black</p>
              </div>
            </div>
          </div>
      </div>
      <div className="vertical-padding-small center">
        <div className="container">
          <div id="sponsors">
            <h2>Some of our Sponsors</h2>
            <div className="hr hr1"></div>
            <br/><br/>
            <div className="columns">
              <div className="one_third column">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.cheproximity.com.au/">
                  <img
                    src={CHE}
                    alt="CHE Proximity"
                    height="78"
                    width="auto" />
                </a>
              </div>
              <div className="one_third column">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.omd.com/">
                  <img
                    src={OMD}
                    alt="OMD"
                    height="78"
                    width="auto" />
                </a>
              </div>
              <div className="one_third column">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://bdamarketing.com.au/">
                  <img
                    src={BDA}
                    alt="BDA"
                    height="78"
                    width="auto" />
                </a>
              </div>
            </div>
            <br /><br />
            <div className="columns">
              <div className="one_third column">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.castrol.com/en_au/australia/home.html">
                  <img
                    src={Castrol}
                    alt="Castrol"
                    height="70"
                    width="auto" />
                </a>
              </div>
              <div className="one_third column">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://pjse.com.au/">
                  <img
                    src={PeterJones}
                    alt="Peter Jones"
                    height="70"
                    width="auto" />
                </a>
              </div>
              <div className="one_third column">
                <a
                  href="#">
                  <img
                    src={MDAOA}
                    alt="MDAOA"
                    height="70"
                    width="auto" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage